import React from 'react' 


const Skills = () => {

  return (
    <>
      
          <div><p>I am a junior sofware developer with 20 years experience in nursing and 6 years in occupational health. I feel comfortable under pressure, leading projects and solving large complex problems. Due to my love for tech and problem solving, I became a software engineering graduate and am now looking for a company which will allow me to utilise my skills and to learn and extend my experience within a supportive team environment.</p>
          </div>
    
    </>
  )




}

export default Skills